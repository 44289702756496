.background-img {
    background-image: url("../images/ManOnMountain1_gray.jpg");
    box-sizing: border-box;
    background-size: cover;
    height: 500px;
}

.background-img2 {
    height: 500px;
    background-image: url("../images/bg3.jpg");
    background-size: cover;
}

.background-img3 {
    height: 500px;
    background-image: url("../images/RiseInOverdoses.webp");
    background-size: cover;
}

.box {
    display: table;
    width: 100%;
}

.section {
    height: 500px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section__container {
    box-sizing: border-box;
}

.header__text {
    font-size: medium;
    text-align: center;
}

.row {
    margin-left: 50px;
    margin-right: 50px;
}

img {
    box-sizing: border-box;
    width: 100%;
    height: auto;
}

.section__footer {
    background-color: rgb(51, 51, 51);
    color: #9c9c9c;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 1.5em;
    padding: 37px 0 39px;
    word-spacing: 1px;
    text-align: center;
}

.footer__link {
    color: white
}

.footer__copyright {
    text-align: center;
    color: #ffffff;
}