/* Header Component Styles */
/*@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

:root {
    --header-height: 20px;
    --z-fixed: 1;

}

.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: var(--z-fixed);
    height: fit-content;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin: 1rem;
}



.logo img {
    width: 200px;
    /* Adjust the width of your logo image */
    height: auto;
}

img {
    vertical-align: middle;
    border: 0;
    box-sizing: 0;
}

nav ul {
    list-style-type: none;
    /* Remove bullet points from the list */
    padding: 0;
    display: flex;
}

nav li {
    margin-right: 20px;

    /* Spacing between navigation links */
}

nav a {
    box-shadow: inset 0 0 0 0 #54b3d6;
    text-decoration: none;
    color: black;
    margin: 0 -.25rem;
    padding: 0 .25rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

nav a:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;
    /* Change text color on hover (you can choose any color you like) */
}